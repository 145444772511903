import $ from 'jquery';

const WHITE = 'rgb(255, 255, 255)';

const fieldLength = (field, minLength) => {
  var color = WHITE;
  // Autofill values don't show up for us, but if autofill has been used, the background colour changes from white
  // Apparently boolean background defaults to black, possibly because it's a hidden field, so skip this step for booleans
  if (!$(field).hasClass('boolean')) {
    color = $(field).css('background-color');
  }

  // Test and compare background colour to catch autofill answers
  if (field.value.length >= minLength || color !== WHITE) {
    $(field).removeClass('missing');
    if ($(field).hasClass('boolean')) {
      $(field)
        .closest('.checkbox-holder')
        .removeClass('missing');
    }
    return true;
  }

  $(field).addClass('missing');

  if ($(field).hasClass('boolean')) {
    $(field)
      .closest('.checkbox-holder')
      .addClass('missing');
  }

  return false;
};

export default fieldLength;
