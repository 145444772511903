import $ from 'jquery';
import { ajaxCall } from 'ajax_call';
import toggleCheckbox from 'toggle_checkbox';

const InlineForms = () => {
  $(document).on('click', '.show-form', event => {
    if (event.target.nodeName === 'SELECT') {
      return;
    }

    if ($(event.target).hasClass('archive')) {
      return;
    }

    if ($(event.target).hasClass('fa-trash-alt')) {
      return;
    }

    if ($(event.target).hasClass('fa-download')) {
      return;
    }

    event.preventDefault();

    var row = $(event.target);
    var type = row.data().type;

    if (type === undefined) {
      row = $(event.target)
        .closest('.row-parent')
        .find('.tr');
      type = row.data().type;
    }

    // Disable the create button
    $('.btn.show-form').addClass('disabled');

    // Close all previously open forms and remove (previous) highlights - the new one is added below
    $('.form').addClass('hidden');
    $('.tr').removeClass('highlight');
    $('.tr').removeClass('hide-form');

    // Only add "show-form" to pages that are still inline
    if (
      $('.table.customers').length > 0 ||
      $('.task-group-header').length > 0 ||
      $('.section.squads').length > 0 ||
      $('.user-header').length > 0 ||
      $('.table.guests').length > 0
    ) {
      $('.tr').addClass('show-form');
    }

    var attributes = row.data()[type];
    if (attributes) {
      // Update: highlight the row, fold out the form, and populate it so it can be used for update
      row.addClass('highlight');
      // Change the holding row so that it can be used to close the form
      row.removeClass('show-form');
      row.addClass('hide-form');
      showAndPopulateForm(type, attributes);
    } else {
      var formSelector = '.' + type + '-form';
      // Create: use the first form in the page
      if ($(formSelector).length > 0) {
        $(formSelector)[0].classList.remove('hidden');
      }
      var $form = $('.new_' + type);
      if ($form.length > 0) {
        $form[0].reset();
      }
    }

    // If someone has edited the user role inline, the form value will be wrong, override it now
    updateRole(row);
  });

  $(document).on('click', '.hide-form', event => {
    event.preventDefault();

    // Close all forms and re-enable the create button
    $('.form').addClass('hidden');
    $('.choir-update-form').removeClass('hidden');
    $('.btn.show-form').removeClass('disabled');

    var $parentRow = $(event.target)
      .closest('.row-parent')
      .find('.tr');

    // De-highlight the row and relabel it as show-form
    $parentRow.removeClass('highlight');
    $parentRow.addClass('show-form');
    $parentRow.removeClass('hide-form');
  });

  $(document).on('click', '.reset-trial', event => {
    sendUpdate(event);
  });

  $(document).on('change', '.update', event => {
    sendUpdate(event);
  });

  $(document).on('click', '.ts-and-cs .checkbox', event => {
    event.preventDefault();

    var oldValue = $('#ts_and_cs').val();
    parent = $(event.target).closest('.checkbox-holder');

    toggleCheckbox(parent, oldValue != '1');
  });

  const sendUpdate = event => {
    const $input = $(event.target);
    const $form = $input.closest('form');
    const method = $('input[name="_method"]').val();
    const data = buildParams($input, $form);

    if ($form.data().feature) {
      const paramName = Object.keys(data.setting)[0];
      const adding = updateSettingParams(paramName);

      // If the setting is being removed, update the value to 0
      // so that in rails it will be set to false
      data.setting[paramName] = adding;
    }

    const url = $form.attr('action');

    ajaxCall(method, url, data);
  };

  const updateSettingParams = paramName => {
    const keyName = '.setting_' + paramName;

    const $parent = $(keyName).closest('.checkbox-holder');

    $parent.toggleClass('checked');

    if ($parent.hasClass('time')) {
      $('.checkbox-holder.invoices').toggleClass('read-only');
      $('.checkbox-holder.invoices')
        .find('.disabled-info')
        .hide();
    }

    if ($parent.hasClass('invoices')) {
      $('.checkbox-holder.time').toggleClass('read-only');
      $('.checkbox-holder.time')
        .find('.disabled-info')
        .hide();
    }

    if ($parent.hasClass('skills')) {
      $('.checkbox-holder.generate').toggleClass('read-only');
      $('.checkbox-holder.generate')
        .find('.disabled-info')
        .hide();
    }

    if ($parent.hasClass('generate')) {
      $('.checkbox-holder.skills').toggleClass('read-only');
      $('.checkbox-holder.skills')
        .find('.disabled-info')
        .hide();
    }

    if ($parent.hasClass('templates')) {
      $('.checkbox-holder.make_public').toggleClass('read-only');
      $('.checkbox-holder.make_public')
        .find('.disabled-info')
        .hide();
    }

    if ($parent.hasClass('self_service')) {
      $('.checkbox-holder.make_public').toggleClass('read-only');
      $('.checkbox-holder.make_public')
        .find('.disabled-info')
        .hide();
    }

    if ($parent.hasClass('make_public')) {
      $('.checkbox-holder.self_service').toggleClass('read-only');
      $('.checkbox-holder.self_service')
        .find('.disabled-info')
        .hide();

      $('.checkbox-holder.templates').toggleClass('read-only');
      $('.checkbox-holder.templates')
        .find('.disabled-info')
        .hide();
    }

    // The HTML always has a value of "1" for a checkbox
    // Work out if it is now set or not and pass this information back
    if ($parent.hasClass('checked')) {
      return true;
    }

    return false;
  };

  const updateRole = row => {
    var roleField = row.find('#user_role');

    if (roleField.length > 0) {
      $('.foldout-form')
        .find('#user_role')
        .val(roleField.val());
    }
  };

  const buildParams = ($input, $form) => {
    const inputNameParts = nameParts($input.attr('name'));
    const token = $('input[name="authenticity_token"]', $form).val();

    var currentPath = window.location.href;

    const params = {
      authenticity_token: token,
      active: currentPath.split('active=')[1],
      [inputNameParts[0]]: {
        [inputNameParts[1]]: $input.val()
      }
    };

    return params;
  };

  const showAndPopulateForm = (type, attributes) => {
    var formSelector = '.' + type + '-' + attributes['id'];
    // Music forms need to set the event they are for
    if (attributes['for']) {
      formSelector = '.' + type + '_for-' + attributes['for'];
    }

    var $form = $(formSelector).find('.form');
    $form.removeClass('hidden');

    for (var key in attributes) {
      var attributeName = '#' + type + '_' + key;
      var attributeValue = attributes[key];
      if (key == 'time' && attributes[key]) {
        attributeValue = attributes[key].split('T')[1].split(':00.')[0];
      }
      $form.find(attributeName).val(attributeValue);
    }

    var $editForm = $form.find('form');
    if ($editForm.length > 0 && attributes['id']) {
      var action = $editForm[0].action;
      if (!action.endsWith(attributes['id'])) {
        $editForm[0].action = action + '/' + attributes['id'];
      }
    }
    $('input.' + type).prop('disabled', false);
  };

  // e.g. "setting[vacation_default_number]" => ["setting", "vacation_default_number"]
  const nameParts = name => name.split('[').map(part => part.replace(']', ''));
};

export default InlineForms;
