import $ from 'jquery';
import fieldLength from 'field_length';
import { setError } from 'utils';

const RequiredFields = () => {
  $(document).on('turbolinks:load', event => {
    $('input[type="submit"]').prop('disabled', false);
    $('input').removeClass('missing');
  });

  // When user navigates back into the fields, re-enable submit,
  // so that we are notified next time they try and submit
  $(document).on('mouseenter', '.required-fields', event => {
    event.preventDefault();

    $('.falert.validate').hide();
    $('input[type="submit"]').prop('disabled', false);
  });

  // When a user tries to submit, validate everything if there is a required-fields section
  $(document).on('mouseenter', 'input[type="submit"]', event => {
    if ($('.required-fields').length > 0) {
      validateAll(event.target);
    }
  });

  // Validate all the fields inside required fields
  const validateAll = target => {
    $('input[type="submit"]').prop('disabled', true);
    $('input').removeClass('missing');

    var allValid = true;

    var $requiredFields = $(target)
      .closest('.card-body')
      .find('.required-fields');

    if ($requiredFields.length === 0) {
      $requiredFields = $(target)
        .closest('.foldout-holder')
        .find('.required-fields');
    }

    if ($requiredFields.length === 0) {
      $requiredFields = $(target)
        .closest('.form-holder')
        .find('.required-fields');
    }

    if ($requiredFields.length === 0) {
      $requiredFields = $(target)
        .closest('.form-body')
        .find('.required-fields');
    }

    if ($requiredFields.length > 0) {
      var minLength = 1;
      if ($('input.password').length > 0) {
        minLength = parseInt(
          $('input.password')
            .first()[0]
            .getAttribute('minlength')
        );
      }
    }

    $requiredFields.find('input.required').each(function(index, input) {
      if (!fieldLength(input, 1)) {
        allValid = false;
      }
    });

    if (allValid) {
      $('input[type="submit"]').prop('disabled', false);

      var minLengthMet = true;
      $('input.password').each(function(index, input) {
        // Some password fields are optional, skip length check if they are empty
        if (input.value.length === 0) {
          return;
        }

        // But if something is set, it must meet the minimum length requirement
        if (!fieldLength(input, minLength)) {
          minLengthMet = false;
        }
      });

      if (minLengthMet) {
        if (
          $('.change-password input').val() !=
          $('.confirm-password input').val()
        ) {
          setError('errors.password.no_match', findAlertField(target));
        }
      } else {
        setError('errors.password.too_short', findAlertField(target));
      }
    } else {
      setError('errors.required', findAlertField(target));
    }
  };

  const findAlertField = target => {
    var alertField = $('.falert');
    if (alertField.length > 1) {
      alertField = $(target)
        .closest('.foldout-holder')
        .find('.falert');
    }
  };
};

export default RequiredFields;
